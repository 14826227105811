class AnimatedNumber {

  constructor(observer, liNode, id) {
    this.id = id;
    this.duration = 1.2;
    this.textNode = null;
    this.n = null;
    this.initialTimestamp = null;
    this.liNode = liNode;

    console.log("AnimatedNumber:", this);
    this.textNode = this.liNode.querySelector('strong');
    if (!this.textNode) {
      console.log("AnimatedNumber: No <strong> element found in ", this.liNode);
      return;
    }
    // Only count in decimal places if they started it.
    this.useFloat = this.textNode.textContent.indexOf('.') > -1;
    this.n = this.useFloat ? parseFloat(this.textNode.textContent) : parseInt(this.textNode.textContent);
    if (!this.n) {
      console.log("No .statistic found", this);
      return;
    }

    // Store the updateAnimation function on the thing we observe.
    this.textNode.updateAnimation = this.updateAnimation.bind(this);
    observer.observe(this.textNode);
  }
  updateAnimation(t) {
    if (this.initialTimestamp === null) {
      this.initialTimestamp = t;
    }
    const f = Math.min(1, (t - this.initialTimestamp) / this.duration / 1000);
    const n = this.useFloat ? Math.round(this.n * f * 10) / 10 : Math.round(this.n * f);
    this.textNode.innerText = n.toLocaleString();
    if (f < 1) {
      window.requestAnimationFrame(this.updateAnimation.bind(this));
    }
  }
}

class PnpCustom {

  constructor() {
    window.phpcustomv = 1;

  }

  bootCommentForm() {
    const form = document.getElementById('CommentForm_form');
    if (!form) return;
    const tokenElement = document.createElement('input');
    tokenElement.type = 'hidden';
    tokenElement.name = 'token';
    form.appendChild(tokenElement);
    const submit = form.querySelector('#CommentForm_submit');
    submit.addEventListener('click', e => this.antispam(e, form, tokenElement, submit));
    return this;
  }

  async antispam(e, form, tokenElement, submit) {
    // console.log('antispam', { form, tokenElement, submit, bs: submit.byScript });
    if (submit.byScript) {
      // console.log("pressed earlier");
      // We're calling this after tokenisation.
      // reset just in case.
      setTimeout(() => {
        // This should never get executed...
        submit.byScript = false;
        submit.disabled = false;
        submit.textValue = 'Submit';
      }, 5000);
      return;
    }
    // We're being called because the user clicked submit.
    submit.byScript = true;
    submit.disabled = true;
    submit.textContent = 'Submitting...';
    e.preventDefault();
    e.stopPropagation();
    const d = { CommentForm_submit: 1 }, formData = new FormData(form);
    for (const [key, value] of formData) {
      // console.log("form data of", key, formData, formData.get(key));
      d[key] = value;
    }
    // console.log("data", d);
    const response = await fetch('/tokenise-data', { method: 'POST', body: JSON.stringify(d) })
      .then(response => response.json());
    if (!('token' in response)) {
      submit.byScript = false;
      submit.disabled = true;
      submit.textValue = 'Submit';
      alert("Sorry, there was an unexpected error!");
      return;
    }
    tokenElement.value = response.token;
    setTimeout(() => { submit.disabled = false; submit.click(); }, 5000);
  }

  bootUmami() {
    document.querySelectorAll('video').forEach(vid => {
      if (!(vid.umamiSetup)) {
        vid.umamiSetup = true;
        vid.addEventListener('play', () => {
          if (window.umami) {
            let fn = (vid.querySelector('source')?.src ?? '').replace(/^.*\/(.*)$/, '$1');
            umami.track('play-video', { video: fn });
          }
        });
      }
    });
    return this;
  }

  bootMenu() {
    const menuToggle = document.getElementById('menu-toggle');
    const headerNav = document.getElementById('header-nav');

    const toggleAriaPressed = (el) => {
      let newValue = el.getAttribute('aria-pressed') !== 'true';
      el.setAttribute('aria-pressed', newValue ? 'true' : 'false');
      return newValue;
    }

    menuToggle.addEventListener('click', () => {
      if (toggleAriaPressed(headerNav)) {
        document.body.classList.add('mobile-menu-open');
      }
      else {
        document.body.classList.remove('mobile-menu-open');
      }
    });

    const submenuToggleClicked = (li) => {
      const btn = li.querySelector(':scope > .submenu-toggle');
      if (!btn) {
        return;
      }
      toggleAriaPressed(btn);
      li.classList.toggle('show-menu');
      btn.textContent = li.classList.contains('show-menu') ? '-' : '+';
    };

    // Ensure the current page is revealed.
    [].forEach.call(headerNav.querySelectorAll('li.trail'), li => {
      submenuToggleClicked(li);
      // li.classList.add('show-menu');
    });

    // Provide controllers for menu toggles.
    [].forEach.call(document.querySelectorAll('#header-nav .submenu-toggle, #header-nav span.item'),
      el => el.addEventListener('click', () => submenuToggleClicked(el.parentElement))
    );

    // Toggles
    const saved = Object.assign({ campaigner: false, dyslexic: false, highContrast: false }, JSON.parse(localStorage.getItem('toggles') || '{}')),
      toggleNodes = {};
    ['dyslexic-font', 'campaigner-mode', 'high-contrast'].forEach(toggleName => {
      let node = document.getElementById(`toggle-${toggleName}`);
      if (node) {
        node.checked = saved[toggleName];
        let sync = (e) => {
          document.body.classList[node.checked ? 'add' : 'remove'](toggleName);
          saved[toggleName] = node.checked;
          localStorage.setItem('toggles', JSON.stringify(saved));
          e && umami && umami.track(toggleName + '-' + (node.checked ? 'on' : 'off'));
        };
        node.addEventListener('change', sync);
        sync();
      }
    });

    return this;
  }

  bootShareLinks() {
    [].forEach.call(document.querySelectorAll('ul.social-share-links a'), link => {
      if (!link.pnp23processed) {
        link.pnp23processed = true;
        if (link.dataset.sm === 'share') {
          if (!navigator.share) {
            link.remove();
          }
          else {
            link.addEventListener('click', shareByApi);
          }
        }
        else if (link.dataset.sm === 'docs') {
          link.addEventListener('click', (e) => this.shareByCopy(e));
        }
        else if (link.dataset.sm === 'mastodon') {
          link.addEventListener('click', (e) => this.shareByMastodon(e));
        }
        else {
          // Normal URL
          // console.log("skipping", link);
        }
      }
    });
    return this;
  }

  bootAddClickedClassToDownloads() {
    // Download links: add .clicked class when clicked. (seems to require target=_blank)
    [].forEach.call(document.querySelectorAll('ul.downloads-list>li>a'), a => {
      a.addEventListener('click', e => { a.classList.add('clicked'); });
    });
    return this;
  }

  bootScrollToTop() {
    let observer = new IntersectionObserver((entries) => {
      document.body.classList[(entries[0].boundingClientRect.top < 0) ? 'add' : 'remove']('scrolled');
    });
    observer.observe(document.getElementById('scroll-to-top-target'));
    document.getElementById('scroll-to-top').addEventListener('click', e => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    return this;
  }

  bootAnimatedNumbers() {
    // Create an IntersectionObserver that will call 'updateAnimation' on the
    // target, where that exists.
    let observer = new IntersectionObserver(
      (entries, observer) =>
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.target.updateAnimation) {
            console.log("observer detected");
            window.requestAnimationFrame(entry.target.updateAnimation);
          }
        }),
      {
        // rootMargin: '0px',
        threshold: 1.0,
      }
    );

    document.querySelectorAll('ul.stats-list>li').forEach((part, n) => new AnimatedNumber(observer, part, n));
    return this;
  }

  copyToClipbard(textToCopy) {
    const input = document.createElement('input');
    input.value = textToCopy;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, textToCopy.length); /* For mobile devices */
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  shareByCopy(e) {
    e.preventDefault(); e.stopPropagation();
    umami && umami.track('shareByCopy', {});
    const link = e.target;
    if (link.pnp23active) return;
    const orig = link.innerHTML;
    link.pnp23active = true;
    const url = new URL(window.location.href);
    console.log(url);
    url.searchParams.delete('token');
    url.searchParams.delete('cs');
    url.searchParams.delete('cid');
    url.hash = '';
    this.copyToClipbard(url.toString());
    link.innerHTML = 'Copied!';
    setTimeout(() => { link.innerHTML = orig; link.pnp23active = false; }, 1000);
  }

  async shareByApi(e) {
    e.preventDefault(); e.stopPropagation();
    umami && umami.track('shareByApi', {});
    const shareData = {
      title: document.title,
      url: window.location.href
    };

    try {
      await navigator.share(shareData);
      console.log(`Shared successfully`);

    } catch (err) {
      console.error(`Error: ${err}`);
    }
  }

  shareByMastodon(e, previousError) {
    e.preventDefault(); e.stopPropagation();
    umami && umami.track('shareByMastodon', {});
    const link = e.target;
    let mastoDomain = prompt((previousError || '') + 'Enter your Mastodon domain\n(the part after the 2nd @) for example:\nmastodon.social\nkind.social\netc.');
    if (!mastoDomain) {
      return;
    }
    // Handle @myname@my.server and @my.server
    mastoDomain = mastoDomain.replace(/^(?:@?(?:[a-zA-Z0-9_.-]+))?@([a-zA-Z0-9_.-]+)$/, '$1');
    if (!mastoDomain.match(/^[a-zA-Z0-9_.-]*\.[a-zA-Z0-9_-]+$/)) {
      this.shareByMastodon(e, 'That domain did not look right. Try again?\n');
      return;
    }

    let url = link.getAttribute('href').replace(/^#/, `https://${mastoDomain}/share?text=`);
    window.open(url, '_blank');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const pnpCustom = new PnpCustom();
  pnpCustom
    .bootMenu()
    .bootShareLinks()
    .bootAddClickedClassToDownloads()
    .bootAnimatedNumbers()
    .bootScrollToTop()
    .bootCommentForm();
  pnpCustom
    .bootUmami()
    // @todo Insert a pie chart with legend from data in a table.chart-pie
    // Used at https://pw.peopleandplanet.org/university-league/methodology/
    // Current implementation is fine, it uses c3 and d3. These total 330kB
    // which is not bad. But I'm not sure if any others are used.
    // @todo discuss needs with Jack.
    ;
});


